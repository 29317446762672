import Button from '@atoms/Button/Button';
import { FilterCollection, FilterEvent } from '@hooks/useFilters';
import useTranslations from '@hooks/useTranslations';
import DateRangePicker from '@molecules/DateRangePicker/DateRangePicker';
import Modal from '@molecules/Modal/Modal';
import SearchInput from '@molecules/SearchInput/SearchInput';
import { isRangeFilter } from '@type-declarations/filter';
import { FilterGroup } from '@type-declarations/filters';
import { isActiveDateFilter } from '@utils/filters/dateFilter';
import { isActiveDistanceFilter } from '@utils/filters/distanceFilter';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { Fragment, useState } from 'react';

import Fieldset from './Fieldset';
import styles from './Filters.module.scss';

const MODAL_ID = 'filter-modal';

interface Props {
  primary: FilterGroup[];
  secondary: FilterGroup[];
  activeFilters: FilterCollection;
  searchLabel?: string;
  hasDateFilter?: boolean;
  hideSubSubCategories?: boolean;
  onChange: (event: FilterEvent) => void;
  onClear: () => void;
  modifier?: 'searchFilters';
}

export default function Filters({
  primary,
  secondary,
  activeFilters,
  searchLabel,
  hasDateFilter = false,
  hideSubSubCategories = false,
  onChange,
  onClear,
  modifier,
}: Props) {
  const { query } = useRouter();
  const t = useTranslations();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const isActive = (groupKey: keyof FilterCollection, value: string) => {
    const group = activeFilters[groupKey];

    if (group === undefined || group === null) return false;

    if (typeof group === 'boolean') {
      return group;
    }

    if (typeof group === 'string') {
      return group === value;
    }

    if (isRangeFilter(group)) {
      if (groupKey === 'date') {
        return isActiveDateFilter(group, value);
      }

      if (groupKey === 'distance') {
        return isActiveDistanceFilter(group, value);
      }

      return false;
    }

    return group.includes(value);
  };

  return (
    <>
      <div className={clsx(styles.container, modifier && styles[modifier])}>
        {primary.map(group => (
          <Fragment key={`primary-group-${group.key}`}>
            {group.filters.map(filter => {
              const active = isActive(group.key, filter.value);

              return (
                <Button
                  key={`primary-filter-${filter.value}`}
                  label={filter.title}
                  variant="white"
                  className={styles.primaryFilter}
                  onClick={() =>
                    // @ts-expect-error - TODO: Categories group is not supported in our Typescript yet.
                    onChange({ type: group.key, value: filter.value })
                  }
                  data-name={group.key}
                  data-value={filter.value}
                  modifier={active ? 'filterActive' : null}
                  icon={active ? 'CHECK' : null}
                  noAnimation
                />
              );
            })}
          </Fragment>
        ))}

        {hasDateFilter && (
          <DateRangePicker
            onChange={dates => onChange({ type: 'date', value: dates })}
            className={styles.datePicker}
            initialRange={activeFilters.date}
          />
        )}

        {searchLabel && (
          <SearchInput
            name="title"
            label={searchLabel}
            defaultValue={query.title}
            icon
            onChange={value => onChange({ type: 'title', value })}
          />
        )}

        {!!secondary.length && (
          <Button
            label={t.filter}
            iconLeft="FILTER"
            icon="NAV_ARROW_RIGHT"
            className={styles.filterButton}
            onClick={() => setModalIsOpen(true)}
            aria-controls={MODAL_ID}
            aria-expanded={modalIsOpen}
            count={
              (activeFilters.tags?.length || 0) +
              (activeFilters.accessibility?.length || 0) +
              (activeFilters.categories?.length || 0)
            }
          />
        )}
      </div>
      <Modal
        id={MODAL_ID}
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        title={t.filter}
        footer={
          <div className={styles.modalButtonWrapper}>
            <Button
              className={styles.showResultsButton}
              label={t.showResults}
              onClick={() => setModalIsOpen(false)}
              icon="NAV_ARROW_RIGHT"
            />

            <Button
              className={styles.clearFiltersButton}
              onClick={onClear}
              label={t.clearFilters}
              hideLabel
              variant="secondary"
              icon="BIN_MINUS_IN"
            />
          </div>
        }
      >
        {secondary.map((group, i) => (
          <Fieldset
            // eslint-disable-next-line react/no-array-index-key
            key={`${group.key}-${i}`}
            group={group}
            isActive={isActive}
            onChange={onChange}
            activeFilters={activeFilters}
            hideSubSubCategories={hideSubSubCategories}
          />
        ))}
      </Modal>
    </>
  );
}
