import gql from 'graphql-tag';

const quoteSectionFragment = gql`
  fragment quoteSectionFields on QuoteSection {
    quote
    name
    functionName: function_name
    image {
      ...assetFields
      url(width: 100, height: 100, format: "webp")
      placeholder: url(width: 8)
    }
    backgroundColor: background_color {
      color
    }
  }
`;

export default quoteSectionFragment;
