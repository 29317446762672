import { BG_ALTERNATIVE } from '@constants/constants';
import useStore from '@hooks/useStore';
import useTranslations from '@hooks/useTranslations';
import Card from '@molecules/Card/Card';
import TextSection from '@organisms/TextSection/TextSection';
import {
  Event,
  Location,
  News,
  OverviewItem,
  Route,
} from '@type-declarations/overviewItems';
import getInternalRoute from '@utils/getInternalRoute';
import clsx from 'clsx';
import { RefObject } from 'react';

import styles from './CardsGridWrapper.module.scss';

interface Props {
  items: (Location | Event | News | Route | OverviewItem)[];
  scrollToRef?: RefObject<HTMLDivElement>;
  alternateGrid?: boolean;
  showType?: boolean;
  notFoundTitle?: string;
  notFoundText?: string;
  total: number;
}

export default function CardsGridWrapper({
  items,
  total,
  alternateGrid = false,
  showType,
  scrollToRef,
  notFoundTitle,
  notFoundText,
}: Props) {
  const {
    store: { locale },
  } = useStore();

  const t = useTranslations();

  if (!items.length) {
    return (
      <TextSection
        id="not-found-message"
        typename="TextSection"
        title={notFoundTitle || t.nothingFoundTitle}
        text={notFoundText || t.nothingFoundText}
        backgroundColor={{ color: BG_ALTERNATIVE }}
      />
    );
  }
  return (
    <div className={clsx(styles.wrapper, 'u-container-width')}>
      <p className={styles.total}>
        {total} {(total === 1 ? t.result : t.results).toLowerCase()}
      </p>

      <div
        className={clsx(
          'u-grid u-grid--gap-3 u-grid--gap-4@lg u-grid--2 u-grid--3@sm u-grid--4@lg',
          alternateGrid && styles.alternateGrid
        )}
        ref={scrollToRef}
      >
        {items.map(item => (
          <Card
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...item}
            key={item.id}
            id={item.id}
            showType={showType}
            showSaveButton
            imageSize="sm"
            imageSizeBreakpoints={['md@from-md', 'lg@from-lg']}
            href={getInternalRoute({ internalLink: item, locale })}
          />
        ))}
      </div>
    </div>
  );
}
