import assetFragment from '@lib/fragments/asset-fragment';
import ctaFragment from '@lib/fragments/cta-fragment';
import gql from 'graphql-tag';

const textImageSectionFragment = gql`
  fragment textImageSectionFields on TextImageSection {
    title
    subtitle
    text
    cta {
      ...ctaFields
    }
    image {
      ...assetFields
      url(width: 1920, format: "webp")
      placeholder: url(width: 8)
    }
    imageAlt: alt_text
    textLeft: text_left
    backgroundColor: background_color {
      color
    }
  }
  ${assetFragment}
  ${ctaFragment}
`;

export default textImageSectionFragment;
