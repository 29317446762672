type SearchablePageType =
  | 'NewsPage'
  | 'EventPage'
  | 'LocationPage'
  | 'RoutePage'
  | 'ContentPage'
  | 'MainCategory'
  | 'SubCategory'
  | 'SubSubCategory';

const pageTypes = {
  news: 'NewsPage',
  event: 'EventPage',
  location: 'LocationPage',
  route: 'RoutePage',
  content: 'ContentPage',
  mainCategory: 'MainCategory',
  subCategory: 'SubCategory',
  subSubCategory: 'SubSubCategory',
} as const;

const getSearchablePageType = (value: string): SearchablePageType | null =>
  pageTypes[value as keyof typeof pageTypes] || null;

export function prepareContentTypeForQuery(
  contentTypes?: string | string[]
): SearchablePageType[] {
  const allPageTypes = Object.values(pageTypes);

  if (!contentTypes) {
    return allPageTypes;
  }

  if (typeof contentTypes === 'string') {
    const pageType = getSearchablePageType(contentTypes);
    if (!pageType) {
      return allPageTypes;
    }

    return [pageType];
  }

  if (!contentTypes.length) {
    return allPageTypes;
  }

  return contentTypes
    .map(contentType => getSearchablePageType(contentType))
    .filter(Boolean) as SearchablePageType[];
}
