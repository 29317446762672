import Button from '@atoms/Button/Button';
import RouteSchema from '@atoms/schemas/routeSchema';
import ShareButton from '@atoms/ShareButton/ShareButton';
import useTranslations from '@hooks/useTranslations';
import Hero from '@organisms/Hero/Hero';
import InteractiveMap from '@organisms/InteractiveMap/InteractiveMap';
import RouteTimeline from '@organisms/RouteTimeline/RouteTimeline';
import RouteTopBar from '@organisms/RouteTopBar/RouteTopBar';
import Sections from '@organisms/Sections/Sections';
import { RoutePage as Props } from '@type-declarations/page';
import { MapLocation } from '@type-declarations/prepr';
import { getHeroColor } from '@utils/getHeroColor';
import { useMemo } from 'react';

function RouteTemplate({ page }: { page: Props }) {
  const {
    id,
    title,
    introText,
    image,
    alignment,
    gpx,
    pdf,
    distance,
    duration,
    stops,
    start,
    startLocation,
    end,
    endLocation,
    routeType,
  } = page;
  const { downloadRoutePdf } = useTranslations();

  const heroColor = useMemo(() => getHeroColor({ page }), [page]);

  const stopsWithLocation = stops?.filter(stop => !!stop.location[0]) || [];

  const locations: MapLocation[] = stopsWithLocation.map((stop, index) => ({
    id: stop.id,
    typename: 'LocationPage',
    image: stop.image || stop.location[0].image,
    icon:
      stop.location[0].category?.[0]?.icon ||
      stop.location[0].category?.[0]?.category?.[0]?.icon,
    title: stop.location[0].title,
    address: stop.location[0].address,
    slug: stop.location[0].slug,
    index,
    pos: [
      Number(stop.location[0].coordinates.latitude),
      Number(stop.location[0].coordinates.longitude),
    ],
  }));

  if (startLocation?.latitude && startLocation?.longitude) {
    locations.push({
      id: 'route-start',
      typename: 'LocationPage',
      title: 'Start',
      icon: 'WHITE_FLAG',
      pos: [Number(startLocation.latitude), Number(startLocation.longitude)],
      disablePopup: true,
    });
  }

  if (
    endLocation?.latitude &&
    endLocation?.longitude &&
    startLocation?.latitude !== endLocation?.latitude &&
    startLocation?.longitude !== endLocation?.longitude
  ) {
    locations.push({
      id: 'route-end',
      typename: 'LocationPage',
      title: 'End',
      icon: 'WHITE_FLAG',
      pos: [Number(endLocation.latitude), Number(endLocation.longitude)],
      disablePopup: true,
    });
  }

  return (
    <>
      <Hero
        pageId={id}
        title={title}
        text={introText}
        image={image}
        colorVariant={heroColor}
        containerSize="narrow"
        showTopBar
        alignment={alignment}
        hideShareButton
      >
        <div className="u-flex u-flex--gap-4 u-flex--wrap">
          {pdf?.url && (
            <Button
              label={downloadRoutePdf}
              iconLeft="DOWNLOAD"
              href={pdf.url}
            />
          )}
          <ShareButton />
        </div>
      </Hero>
      <RouteTopBar
        routeType={routeType}
        gpx={gpx}
        distance={distance}
        duration={duration}
        stopsCount={stops?.length}
      />
      <InteractiveMap gpx={gpx} locations={locations} />
      <RouteTimeline stops={stopsWithLocation} start={start} end={end} />
      <Sections sections={page.sections} />

      <RouteSchema page={page} />
    </>
  );
}

export default RouteTemplate;
