import {
  eventItem,
  locationItem,
  newsItem,
  routeItem,
} from '@lib/fragments/overview-fragment';
import gql from 'graphql-tag';

const STORE_EXPLORE_GROUPS_QUERY = ({
  withContent,
}: {
  withContent?: boolean;
}) => gql`
  query STORE_EXPLORE_GROUPS_QUERY($locale: String, $slugs: [String]) {
    StoreExploreGroups(
      locale: $locale
      limit: 100
      where: { _slug_any: $slugs }
      sort: publish_on_ASC
    ) {
      items {
        id: _id
        slug: _slug
        title
        ${
          withContent
            ? `
              items {
                ... on Model {
                  id: _id
                  typename: __typename
                }
                ...locationItem
                ...eventItem
                ...routeItem
                ...newsItem
              }
            `
            : ''
        }
      }
    }
  }
  ${withContent ? locationItem : ''}
  ${withContent ? eventItem : ''}
  ${withContent ? routeItem : ''}
  ${withContent ? newsItem : ''}
`;

export default STORE_EXPLORE_GROUPS_QUERY;
