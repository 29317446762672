import assetFragment from '@lib/fragments/asset-fragment';
import gql from 'graphql-tag';

const imageSliderSectionFragment = gql`
  fragment imageSliderSectionFields on ImageSliderSection {
    images {
      __typename
      url(width: 800, height: 500, format: "webp")
      placeholder: url(width: 8)
      ...assetFields
    }
    backgroundColor: background_color {
      color
    }
  }
  ${assetFragment}
`;

export default imageSliderSectionFragment;
