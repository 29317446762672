import assetFragment from '@lib/fragments/asset-fragment';
import { localizationsFields } from '@lib/fragments/localizations';
import {
  sectionsFields,
  sectionsFragment,
} from '@lib/fragments/sections-fragment';
import { seoFields } from '@lib/fragments/seo-fragment';
import gql from 'graphql-tag';

const NEWS_PAGE_QUERY = gql`
  query NEWS_PAGE_QUERY($slug: String, $locale: String) {
    NewsPage(slug: $slug, locale: $locale) {
      typename: __typename
      id: _id
      slug: _slug
      locales: _locales
      publishOn: _last_published_on
      title
      introText: intro_text
      image {
        ...assetFields
        url(width: 1920, format: "webp")
      }
      alignment { 
        focusPoint: focus_point
      }
      heroColor: hero_color {
        color
      }
      # SubSub
      category {
        heroColor: hero_color {
          color
        }
        # Sub
        category {
          colorPalette: color_palette
          heroColor: hero_color {
            color
          }
          # Main
          category {
            colorPalette: color_palette
            heroColor: hero_color {
              color
            }
          }
        }
      }
      tags: content_tags { 
        id: _id
        slug: _slug
        title
      }

      ${localizationsFields}
      ${sectionsFields}
      ${seoFields}
    }
  }
  ${assetFragment}
  ${sectionsFragment}
`;

export default NEWS_PAGE_QUERY;
